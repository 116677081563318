import * as React from 'react';
import s from './OfferingName.st.css';
import { Text } from 'wix-ui-tpa/Text';

import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../context/running-environment-context';

interface OfferingNameProps extends RunningEnvironmentProps {
  children?;
  onClick?;
  url?;
}

const OfferingNameComponent = ({
  runningEnvironment,
  onClick,
  children,
  url,
  ...rest
}: OfferingNameProps) => {
  const { isMobile } = runningEnvironment;
  let hrefAttribute;
  if (url) {
    // GBT: this is because in old platform there is no url, and it causes an empty href.
    hrefAttribute = { href: url };
  } else {
    hrefAttribute = {};
  }
  return (
    <div className={s.root}>
      <a
        {...hrefAttribute}
        className={s.link}
        onClick={onClick}
        role="link"
        tabIndex={0}
        aria-label={children || 'Meeting Name'}
      >
        <Text
          {...s('offeringName', { disableFontSize: isMobile, isMobile }, rest)}
          typography="smallTitle"
          tagName="h3"
        >
          {children}
        </Text>
      </a>
    </div>
  );
};

export const OfferingName = withRunningEnvironmentContext(
  OfferingNameComponent,
);
